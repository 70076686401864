import React from "react"
import { navigate } from "gatsby"

import right from "../../icons/right.svg"
import wrong from "../../icons/wrong.svg"

import Layout from "../../layouts"
import { getNewestVersion } from "../../api/version"

import "./product.css"

class Product extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      windows: "",
      mac: "",
    }
  }

  navigateSellActiveCode = () => {
    navigate("/membership/sellActiveCode")
  }

  componentDidMount() {
    this.init()
  }

  init = async () => {
    const res = await getNewestVersion()
    if (res.data.code === "0000") {
      const data = res.data.data
      this.setState({ windows: data.feature.windows, mac: data.feature.mac })
    }
  }

  render() {
    const rightIcon = (
      <img alt="" src={right} className="mdnice-membership-table-icon" />
    )
    const wrongIcon = (
      <img alt="" src={wrong} className="mdnice-membership-table-icon-wrong" />
    )
    const functionList = [
      "markdown 编辑和排版",
      "云端文件同步",
      "云端文件管理",
      "全部职位订阅",
      "本地文件系统管理",
      "图片长期存储",
      "图片云端压缩",
      "本地图片自动上传",
      "支持设备",
      "试用期",
      "新功能体验",
      "其它特性",
      "相关教程",
    ]
    const functionDom = functionList.map((item, index) =>
      index % 2 == 0 ? (
        <div className="mdnice-membership-table-row">{item}</div>
      ) : (
        <div className="mdnice-membership-table-row-dark">{item}</div>
      )
    )

    const localEditorList = [
      rightIcon,
      rightIcon,
      rightIcon,
      rightIcon,
      rightIcon,
      rightIcon,
      rightIcon,
      rightIcon,
      "激活码激活的电脑设备",
      "7 天",
      "优先支持",
      "本地编辑，无网络可正常使用",
      <a href="/article/user/desktop/">教程</a>,
    ]
    const localEditorDom = localEditorList.map((item, index) =>
      index % 2 == 0 ? (
        <div className="mdnice-membership-table-row">{item}</div>
      ) : (
        <div className="mdnice-membership-table-row-dark">{item}</div>
      )
    )

    const webEditorList = [
      rightIcon,
      rightIcon,
      rightIcon,
      rightIcon,
      wrongIcon,
      wrongIcon,
      wrongIcon,
      wrongIcon,
      "Chrome 浏览器",
      "无",
      "支持",
      "网页编辑，更换电脑无障碍",
      <a href="/articles">教程</a>,
    ]
    const webEditorDom = webEditorList.map((item, index) =>
      index % 2 == 0 ? (
        <div className="mdnice-membership-table-row">{item}</div>
      ) : (
        <div className="mdnice-membership-table-row-dark">{item}</div>
      )
    )

    const extentionList = [
      rightIcon,
      wrongIcon,
      wrongIcon,
      wrongIcon,
      wrongIcon,
      wrongIcon,
      wrongIcon,
      wrongIcon,
      "Chrome 浏览器",
      "无",
      "支持",
      "嵌入公众号后台，无缝衔接",
      <a href="/article/user/extension/">教程</a>,
    ]
    const extentionDom = extentionList.map((item, index) =>
      index % 2 == 0 ? (
        <div className="mdnice-membership-table-row">{item}</div>
      ) : (
        <div className="mdnice-membership-table-row-dark">{item}</div>
      )
    )
    return (
      <Layout isSpacerDisplay={true} title="产品功能">
        <div className="mdnice-membership-container">
          <div className="mdnice-membership-table-container">
            <div className="mdnice-membership-sidebar">
              <div
                className="mdnice-membership-sidebar-row-active"
                onClick={() => navigate("/membership/product")}
              >
                <p>产品功能</p>
              </div>
              <div
                className="mdnice-membership-sidebar-row"
                onClick={() => navigate("/membership/sell-active-code")}
              >
                <p>激活码购买</p>
              </div>
            </div>
            <div className="mdnice-membership-table">
              <div
                className="mdnice-membership-table-coloum"
                style={{ borderRight: "none" }}
              >
                <div className="mdnice-membership-table-row-header">
                  <p>功能特性</p>
                </div>
                <div
                  className="mdnice-membership-table-row-dark"
                  style={{ fontWeight: "bold" }}
                >
                  产品下载
                </div>
                {functionDom}
              </div>
              <div className="mdnice-membership-table-coloum-recommand">
                <div className="mdnice-membership-table-row-header">
                  <img
                    className="mdnice-membership-table-recommand-image"
                    src="https://files.mdnice.com/pic/8601c2ec-4b5c-4aeb-9b69-0d73adc2c3be.png"
                  />
                  <p>本地编辑器</p>
                  <div className="mdnice-membership-table-activecode-btn-container">
                    <a
                      className="mdnice-membership-table-activecode-btn"
                      onClick={() => {
                        navigate("/membership/sell-active-code")
                      }}
                    >
                      激活码购买
                    </a>
                  </div>
                </div>
                <div className="mdnice-membership-table-row-dark">
                  <div className="mdnice-membership-table-activecode-btn-container">
                    <a
                      className="mdnice-membership-table-activecode-btn"
                      onClick={() => {
                        window.open(this.state.mac)
                        navigate("/article/user/desktop/")
                      }}
                    >
                      mac 版
                    </a>
                    <a
                      className="mdnice-membership-table-activecode-btn"
                      onClick={() => {
                        window.open(this.state.windows)
                      }}
                    >
                      windows 版
                    </a>
                  </div>
                </div>

                {localEditorDom}
              </div>
              <div
                className="mdnice-membership-table-coloum"
                style={{ borderLeft: "none" }}
              >
                <div className="mdnice-membership-table-row-header">
                  <p>网页编辑器</p>
                </div>
                <div className="mdnice-membership-table-row-dark">
                  <a
                    className="mdnice-membership-table-btn"
                    onClick={() => {
                      window.open("https://www.mdnice.com")
                    }}
                  >
                    在线访问
                  </a>
                </div>
                {webEditorDom}
              </div>
              <div
                className="mdnice-membership-table-coloum"
                style={{ borderLeft: "none" }}
              >
                <div className="mdnice-membership-table-row-header">
                  <p>浏览器插件</p>
                </div>
                <div className="mdnice-membership-table-row-dark">
                  <a
                    className="mdnice-membership-table-btn"
                    onClick={() => {
                      window.open("https://files.mdnice.com/mdnice_1_0_7.crx")
                    }}
                  >
                    点击下载
                  </a>
                </div>
                {extentionDom}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Product
